<template>
  <Page pageTitle="Welcome back," isFluid customPageClass="is-dashboard">


    <Section customClass="conchim" sizes="is-medium">
      <!--- section content-->
      <div class="box">
        <Level heading="Ho Chi Minh" title="10:30"></Level>
      </div>

    </Section>


    <!-- grid -->
      <Columns>

        <Column>

           <div class="box">
              <Level heading="Ho Chi Minh" title="10:30"></Level>
            </div>

        </Column>

        <Column>
          Second column
         </Column>
        <Column>
          Third column
        </Column>

        <Column>
          Fourth column
         </Column>

      </Columns>
    <!-- grid -->







    <template #rightbar>
      <h4>sidebar</h4>
    </template>
  </Page>
</template>

<script>
// 'layout-with-rightbar': () => import('@/components/layouts/LayoutWithRightbar'),
  import Page from '@/components/layout/Page.vue'
  import Section from '../components/ds/Section.vue'
  import Level from '../components/ds/Level.vue'
  import Columns from '../components/ds/Columns.vue'
  import Column from '../components/ds/Column.vue'

  export default {
    components: {
      Page,
      Section,
      Level,
      Columns,
      Column
    },
  }
</script>

<style lang="scss" scoped>

</style>